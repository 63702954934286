html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-ml {
  background-color: #9c4fff;
}

.border-ml {
  border-color: #9c4fff;
}

.text-ml {
  color: #9c4fff;
}

.ml-pattern {
  background-repeat-y: no-repeat;
  background-size: cover;
  background-position-x: center;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid rgba(255, 255, 255, 0.2);
  border-right: 0.2em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.2em solid rgba(255, 255, 255, 0.2);
  border-left: 0.2em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.image-size {
  width: 468px;
  height: 468px;
}

.max-ml {
  max-width: 500px;
}

@media only screen and (max-width: 600px) {
  .image-size {
    width: 360px;
    height: 360px;
  }
}

@media only screen and (max-width: 450px) {
  .image-size {
    width: 250px;
    height: 250px;
  }
}
